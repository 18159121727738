var priceForm;
(function($) {
    priceForm = function() {
        var priceFormItems = $('.price-form__item--active');
        var totalPrice = 0;

        priceFormItems.each(function(index, elem) {
            $this = $(elem);

            var price = $this.children('.price-form__item-input').data('price');
                price = parseInt(price);
            totalPrice += price;
        });

        totalPrice = new tools.NumberWithSpaces(totalPrice).number;
        $('.price-form__sum').text(totalPrice);
    };

    $('.price-form__item-input').on('click', function(e) {
        $(this).parent().toggleClass('price-form__item--active');
        var value = $(this).attr('checked');
        priceForm();
    });

    $('.price-form').submit(function( event ) {
        event.preventDefault();
        var results = $(this).serializeArray();
        var resultsText = '';

        for (var i = 0; i < results.length; i++) {
            var result = results[i];
            resultsText += result.name + ', ';
        }
        $('.contact-form').fadeIn(300, function() {
            $('body').addClass('scroll-lock');
            $('.components input').val(resultsText);
        });
    });

    $('.contact-form__close-button').on('click', function() {
        $('body').removeClass('scroll-lock');
        $('.contact-form').fadeOut(300);
    });
    $('.contact-form__overlay').on('click', function() {
        $('body').removeClass('scroll-lock');
        $('.contact-form').fadeOut(300);
    });
})(jQuery);