var toggleMenu;

(function($) {
    toggleMenu = function() {
        $topBar = $('.top-bar');
        $('.js-toggle-menu').on('click', function() {
            $('body').toggleClass('scroll-lock');
            $topBar.toggleClass('top-bar--toggle');
        });
    };
})(jQuery);