var animationReveal;

(function($) {
    animationReveal = function() {
        $('.js-reveal-stagger:in-viewport')
        .run(function(){
            var $this = $(this);
                $children = $this.find('.js-reveal-stagger__child');
            TweenMax.staggerTo($children, 1.2, {opacity: 1, ease:Expo.easeOut}, 0.4);
        });

        $('.js-reveal:in-viewport')
        .run(function(){
            var $this = $(this);
            TweenMax.to($this, 1.2, {opacity: 1, y: 0, delay: 0.3, ease:Expo.easeOut});
        });
    };
})(jQuery);