(function($) {
  var $body = $('body');

  $(document).ready(function() {
    $body = $('body');

    objectFitImages();
    toggleMenu();
    priceForm();

    animationReveal();

    $('.project-gallery__wrapper').Chocolat({
      imageSelector: '.project-gallery__image-wrapper',
      separator2: '- '
    });

    if (Foundation.MediaQuery.atLeast('medium')) {
      $('.project-gallery__image-wrapper:nth-child(1)').paroller({ factor: 0.05, type: 'foreground' });
      $('.project-gallery__image-wrapper:nth-child(2)').paroller({ factor: 0.05, type: 'foreground' });
      $('.project-gallery__image-wrapper:nth-child(3)').paroller({ factor: 0.1, type: 'foreground' });
      $('.project-gallery__image-wrapper:nth-child(4)').paroller({ factor: 0.02, type: 'foreground' });
    }
  });

  $(document).scroll(function() {
    animationReveal();
  });

  // Small size submenu toggle
	$(document).on('click', '.menu-item-has-children > a', function(e) {
    e.preventDefault();

		if (!Foundation.MediaQuery.atLeast('medium')) {
      if($(this).parent().hasClass('js-submenu-active')) {
        $(this).parent().removeClass('js-submenu-active');
      } else {
        $('.js-submenu-active').removeClass('js-submenu-active');
        $(this).parent().toggleClass('js-submenu-active');
      }
		}
  });

  function getCookie (name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length === 2) {
      return parts.pop().split(";").shift();
    }
  }

  function setCookie(cookieName, cookieValue, daysUntilExpired) {
    var date = new Date();
    date.setTime(date.getTime() + (daysUntilExpired*24*60*60*1000));
    var expires = "expires="+ date.toUTCString();

    document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
  }

	$(document).on('click', '.notification-banner__close', function() {
    $('.notification-banner').slideUp(300, function() {
      setCookie('popup_closed', 'true', 7);
    });
	});

})(jQuery); // Fully reference jQuery after this point.
